exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atur-profil-js": () => import("./../../../src/pages/atur/profil.js" /* webpackChunkName: "component---src-pages-atur-profil-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kelas-[id]-absensi-index-js": () => import("./../../../src/pages/kelas/[id]/absensi/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-absensi-index-js" */),
  "component---src-pages-kelas-[id]-atur-absensi-index-js": () => import("./../../../src/pages/kelas/[id]/atur/absensi/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-absensi-index-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-index-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-index-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-edit-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/edit.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-edit-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-index-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-index-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-hasil-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/quiz/[quizId]/hasil.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-hasil-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-index-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/quiz/[quizId]/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-index-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-lihat-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/quiz/[quizId]/lihat.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-quiz-id-lihat-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-tambah-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/[lessonId]/quiz/tambah.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-lesson-id-quiz-tambah-js" */),
  "component---src-pages-kelas-[id]-atur-pelajaran-tambah-js": () => import("./../../../src/pages/kelas/[id]/atur/pelajaran/tambah.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-pelajaran-tambah-js" */),
  "component---src-pages-kelas-[id]-atur-quiz-quiz-id-index-js": () => import("./../../../src/pages/kelas/[id]/atur/quiz/[quizId]/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-atur-quiz-quiz-id-index-js" */),
  "component---src-pages-kelas-[id]-detail-index-js": () => import("./../../../src/pages/kelas/[id]/detail/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-detail-index-js" */),
  "component---src-pages-kelas-[id]-jadwal-index-js": () => import("./../../../src/pages/kelas/[id]/jadwal/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-jadwal-index-js" */),
  "component---src-pages-kelas-[id]-nilai-index-js": () => import("./../../../src/pages/kelas/[id]/nilai/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-nilai-index-js" */),
  "component---src-pages-kelas-[id]-quiz-index-js": () => import("./../../../src/pages/kelas/[id]/quiz/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-quiz-index-js" */),
  "component---src-pages-kelas-[id]-quiz-quiz-id-hasil-index-js": () => import("./../../../src/pages/kelas/[id]/quiz/[quizId]/hasil/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-quiz-quiz-id-hasil-index-js" */),
  "component---src-pages-kelas-[id]-quiz-quiz-id-index-js": () => import("./../../../src/pages/kelas/[id]/quiz/[quizId]/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-quiz-quiz-id-index-js" */),
  "component---src-pages-kelas-[id]-quiz-quiz-id-kerjakan-[slug]-index-js": () => import("./../../../src/pages/kelas/[id]/quiz/[quizId]/kerjakan/[slug]/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-quiz-quiz-id-kerjakan-[slug]-index-js" */),
  "component---src-pages-kelas-[id]-silabus-index-js": () => import("./../../../src/pages/kelas/[id]/silabus/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-silabus-index-js" */),
  "component---src-pages-kelas-[id]-syahadah-index-js": () => import("./../../../src/pages/kelas/[id]/syahadah/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-syahadah-index-js" */),
  "component---src-pages-kelas-[id]-utama-index-js": () => import("./../../../src/pages/kelas/[id]/utama/index.js" /* webpackChunkName: "component---src-pages-kelas-[id]-utama-index-js" */),
  "component---src-pages-kelas-coming-soon-js": () => import("./../../../src/pages/kelas/coming-soon.js" /* webpackChunkName: "component---src-pages-kelas-coming-soon-js" */),
  "component---src-pages-kelas-daftar-index-js": () => import("./../../../src/pages/kelas/daftar/index.js" /* webpackChunkName: "component---src-pages-kelas-daftar-index-js" */),
  "component---src-pages-kelas-index-js": () => import("./../../../src/pages/kelas/index.js" /* webpackChunkName: "component---src-pages-kelas-index-js" */),
  "component---src-pages-kelas-kuis-js": () => import("./../../../src/pages/kelas/kuis.js" /* webpackChunkName: "component---src-pages-kelas-kuis-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

